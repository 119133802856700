import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import Image from 'next/image';
import { isNeedToGoPreviousPage, wrap, wrapEvent, wrapOnChange } from '../../helper';
import Icon from '../common/Icon';
import { useContextDispatch } from '../context';
import { loginUser } from './service';
import AmplitudeSendEvent from '../../src/hooks/useAmplitude';
import Spinner from '../common/Spinner';
import { useLocalStorage } from '../../src/hooks/useLocalStorage';

type EmailErrors = { email?: string };
type PasswordErrors = { password?: string };

const PAGES_WITH_REDIRECTION = ['prices', 'confirm-buying-license'];

const Login = () => {
  const { t } = useTranslation();
  const { push, query } = useRouter();
  const { get } = useLocalStorage();
  const { notificationShowAction, authorizedAction, hasExpiredLicenseAction } =
    useContextDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [emailErrors, setEmailErrors] = useState<EmailErrors>({});
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({});
  const [isLoading, setLoading] = useState(false);
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  function validateEmail(value) {
    const newEmailErrors: EmailErrors = {};

    if (value === '') {
      newEmailErrors.email = t('notification_error_email_empty');
    } else if (!emailReg.test(value)) {
      newEmailErrors.email = t('notification_error_email');
    }
    return newEmailErrors;
  }

  function validatePassword(value) {
    const newPasswordErrors: PasswordErrors = {};
    if (value === '') {
      newPasswordErrors.password = t('notification_error_password_empty');
    }
    return newPasswordErrors;
  }

  const onClick = useCallback(() => {
    setEmailErrors(validateEmail(login));

    setPasswordErrors(validatePassword(password));

    if (emailReg.test(login) && password.length) {
      const { type, licenseId, priceId } = query;
      const previousPage = get('previousPage');
      const to = () => {
        setLoading(true);
        AmplitudeSendEvent({ event_type: 'Login', email: login });
        if (type) {
          setTimeout(() => {
            push({
              pathname: `/terminal/confirm-buying-license/${type}${
                licenseId || priceId ? `?licenseId=${licenseId}&priceId=${priceId}` : ''
              }`,
            });
          }, 0);
        } else if (isNeedToGoPreviousPage(previousPage, PAGES_WITH_REDIRECTION)) {
          setTimeout(() => {
            push({ pathname: previousPage });
          }, 0);
        } else {
          setTimeout(() => {
            push({ pathname: `/terminal/personal-licenses` });
          }, 0);
        }
      };

      return loginUser(
        { login, password },
        notificationShowAction,
        authorizedAction,
        hasExpiredLicenseAction,
        to,
        t('sign_up_error')
      );
    }
    return () => {};
  }, [
    authorizedAction,
    emailReg,
    login,
    notificationShowAction,
    password,
    push,
    query,
    t,
    validateEmail,
    validatePassword,
    hasExpiredLicenseAction,
  ]);

  return (
    <div className="content">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="page-login">
          <div className="login-title">
            <div className="h1">
              <h1>{t('login')}</h1>
              <span className="slash">/</span>
              <Link
                href={{
                  pathname: '/terminal/signup',
                  query,
                }}
                passHref
              >
                <span>{t('signUp')}</span>
              </Link>
            </div>
          </div>
          <div className="login-content">
            <form>
              <div className="form-row">
                <div className="input-label">{t('login_form_login_header')}:</div>
                <div className="input">
                  <input
                    value={login}
                    type="text"
                    placeholder={t('login_form_login_placeholder')}
                    onChange={wrapOnChange(setLogin)}
                    id="9334"
                  />
                </div>
                {emailErrors.email && !emailReg.test(login) && (
                  <div className="subinput-info red">{emailErrors.email}</div>
                )}
              </div>

              <div className="form-row">
                <div className="input-label">{t('login_form_password_header')}</div>
                <div className="input">
                  <input
                    value={password}
                    type={isRevealPassword ? 'text' : 'password'}
                    placeholder={t('login_form_password_placeholder')}
                    onChange={wrapOnChange(setPassword)}
                    id="2630"
                  />
                  <div className="password-icon">
                    <Image
                      src={
                        isRevealPassword
                          ? '/img/icons/icon_hide_password.svg'
                          : '/img/icons/icon_show_password.svg'
                      }
                      layout="fill"
                      className="password-visibility-icon"
                      onClick={() => setIsRevealPassword((prevState) => !prevState)}
                      alt="password"
                    />
                  </div>
                </div>
                {passwordErrors.password && !password.length && (
                  <div className="subinput-info red">{passwordErrors.password}</div>
                )}
                <div className="input-info">
                  <Link href="/terminal/lost-password" passHref>
                    <span>{t('login_form_password_footer')}</span>
                  </Link>
                </div>
              </div>
              <div className="form-row">
                <div className="check accent">
                  <label htmlFor="remember">
                    <input
                      id="remember"
                      type="checkbox"
                      checked={remember}
                      className="outtaHere"
                      onChange={wrap(setRemember, !remember)}
                    />
                    <span className="checkbox">
                      <Icon name="24-check" width="24" height="24" file="sprite" />
                    </span>
                    {t('login_form_check')}
                  </label>
                </div>
              </div>

              <div className="form-submit">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={wrapEvent(onClick)}
                  id="9531"
                >
                  <span>{t('login_form_button')}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
