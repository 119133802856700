import axios from 'axios';
import { useSessionStorage } from '../useSessionStorage';
import AmplitudeInstance from './amplitude';

const getInfo = async () => {
  const res = await axios.get('https://ipapi.co/json/');
  return res.data;
};

async function useAmplitude({ event_type, email }) {
  const { get, set } = useSessionStorage();
  let data = get('userInfoLK');
  if (!data) {
    const info = await getInfo();
    set('userInfoLK', info);
    data = info;
  }
  AmplitudeInstance.logEvent({
    event_type: event_type || 'Unknown Event',
    user_id: email || 'unauthorized',
    location_lat: data.latitude,
    location_lng: data.longitude,
    ip: data.ip,
    event_properties: {
      keyString: 'valueString',
      keyInt: 11,
      keyBool: true,
    },
  });
}

function AmplitudeSendEvent({ event_type, email }) {
  useAmplitude({ event_type, email });
}
export default AmplitudeSendEvent;
