import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import App from '../../components/App/App';
import Login from '../../components/Login/Login';

const LoginPage = () => {
  return (
    <App>
      <Login />
    </App>
  );
};
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
  revalidate: 60 * 60,
});

export default LoginPage;
